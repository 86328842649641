<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="pt-3">
      <i class="fa fa-backward"></i>
      <router-link :to="{ name: PublisherEmailPath.name }"> Email Reports </router-link>
    </div>
    <b-card header="Edit Email Report" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
      <b-row>
        <b-col>
          <b-form @submit="onSubmit">
            <div class="row">
              <div class="col-lg-6">
                <b-form-group>
                  <b-form-input v-model="item.vendor_name" type="text" placeholder="vendor name" readonly></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group>
                  <multiselect placeholder="Scoop Report" track-by="name" label="name" v-model="form.scoop"
                    :options="scoopOptions" :multiple="true" :close-on-select="false"></multiselect>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group>
                  <b-form-input v-model="form.name" type="text" placeholder="Name" required>
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group>
                  <b-form-input v-model="form.email" type="email" placeholder="Email" required>
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group>
                  <b-form-checkbox v-model="form.is_active" value="true" unchecked-value="false">Active
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div class="col-12 text-right">
                <b-form-group>
                  <b-button class="mx-1" type="submit" variant="primary">Update</b-button>
                </b-form-group>
              </div>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>

</template>

<script>
import { mapActions, mapState } from "vuex";
import { PublisherEmailPath } from "../../../router/account";
import constant from "../../../store/constant";
export default {
  data() {
    return {
      id: null,
      PublisherEmailPath,
      isVendorSearch: false,
      vendorSearchOptions: [],
      form: {
        name: '',
        email: '',
        scoop: [],
        is_active: false,
      },
      scoopOptions: Object.values(constant.REPORT_SCOOP).map(val => ({ name: val })),
    };
  },
  created() {
    let id = this.$route.params.id;
    this.id = id;
    this.fetchEmailByID(id);
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.emailReport.isLoading,
      isError: (state) => state.emailReport.isError,
      errorMessage: (state) => state.emailReport.errorMessage,
      successMessage: (state) => state.emailReport.successMessage,
      item: (state) => state.emailReport.item,
    }),
  },
  watch: {
    errorMessage: function(message) {
      if (!this.errorMessage) return;
      this.messageAlert('error', message, 5000);
    },
    successMessage: function(message) {
      if (!this.successMessage) return;
      this.messageAlert('success', message);
    },
    item: function(data) {
      if (!data) return;
      this.form = {
        name: data.name,
        email: data.email,
        scoop: data?.scoop?.map(val => ({ name: val })),
        is_active: data.is_active,
      }
    }
  },
  methods: {
    ...mapActions('emailReport', [
      'fetchEmailByID',
      'updateEmailReport',
    ]),
    async onSubmit(event) {
      event.preventDefault();
      const payload = {
        id: this.id,
        name: this.form.name,
        email: this.form.email,
        is_active: this.form.is_active,
        scoop: (this.form.scoop || []).map(val => val.name),
      }
      await this.updateEmailReport(payload);
    },
    messageAlert(icon, title, timer=3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    
  }
}
</script>