var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "pt-3"
  }, [_c('i', {
    staticClass: "fa fa-backward"
  }), _c('router-link', {
    attrs: {
      "to": {
        name: _vm.PublisherEmailPath.name
      }
    }
  }, [_vm._v(" Email Reports ")])], 1), _c('b-card', {
    attrs: {
      "header": "Edit Email Report",
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-row', [_c('b-col', [_c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('b-form-group', [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "vendor name",
      "readonly": ""
    },
    model: {
      value: _vm.item.vendor_name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "vendor_name", $$v);
      },
      expression: "item.vendor_name"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('b-form-group', [_c('multiselect', {
    attrs: {
      "placeholder": "Scoop Report",
      "track-by": "name",
      "label": "name",
      "options": _vm.scoopOptions,
      "multiple": true,
      "close-on-select": false
    },
    model: {
      value: _vm.form.scoop,
      callback: function ($$v) {
        _vm.$set(_vm.form, "scoop", $$v);
      },
      expression: "form.scoop"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('b-form-group', [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Name",
      "required": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('b-form-group', [_c('b-form-input', {
    attrs: {
      "type": "email",
      "placeholder": "Email",
      "required": ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "value": "true",
      "unchecked-value": "false"
    },
    model: {
      value: _vm.form.is_active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_active", $$v);
      },
      expression: "form.is_active"
    }
  }, [_vm._v("Active ")])], 1)], 1), _c('div', {
    staticClass: "col-12 text-right"
  }, [_c('b-form-group', [_c('b-button', {
    staticClass: "mx-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v("Update")])], 1)], 1)])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }